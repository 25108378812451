<template>
	<div class="my-account loaded">
		<b-container class="content intro" fluid>
			<b-row>
				<b-col>
					<h1>Reset Password</h1>
					<h4>Create a new password here, then you will be able to log in to retireve you report.</h4>
				</b-col>
			</b-row>
		</b-container>
		<b-container class="content body" fluid>
			<b-row>
				<b-col>
					<transition name="fade" mode="out-in">
						<div v-if="errorMsg">
							<h4 class="error-msg">{{errorMsg}}</h4>
							<p><a href @click.prevent="tryAgain">Please try again</a>.</p>
						</div>
						<b-form v-else-if="!submitted" class="mb-4" @submit.prevent="submit">
							<password-form-group :password.sync="password" label="Enter New Password" class="mb-35" autofocus is-new />
							<div class="d-flex form-controls">
								<!-- <b-button variant="outline-primary" class="mr-auto" @click="cancel">Cancel</b-button> -->
								<b-button :disabled="disabled" type="submit" class="ml-auto">Reset Password</b-button>
							</div>
						</b-form>
						<div v-else-if="submitting">
							<h4>We're submitting your new password. Please wait.</h4>
						</div>
						<div v-else>
							<h4>Your password has been updated. You may now log in to view your report.</h4>
							<b-button to="/login">Login</b-button>
						</div>
					</transition>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
export default {
	name: 'ResetPassword',
	data() {
		return {
			password: '',
			submitting: false,
			submitted: false,
			errorMsg: '', 
			retryPassword: false
		}
	},
	computed: {
		disabled() {
			return this.password.length < 10
		}
	},
	mounted() {
	},
	methods: {
		submit() {
			// this.$route.query
			this.submitted = this.submitting = true
			const {disclosureId, token} = this.$route.query
			this.$store.commit('set', {disclosureId})
			const url = `${this.$store.getters.apiDisclosurePath}reset-password`
			this.$http
				.post(url, {password: this.password, token})
				.then(e => this.errorMsg = '')
				.catch(e => {
					this.errorMsg = e.response?.data?.ErrorMessage || 'Sorry, there was a problem trying to reset your password.'
					this.retryPassword = e.response?.data?.ErrorCode == "PasswordValidationFailed"
				})
				.finally(() => this.submitting = false)
		},
		tryAgain() {
			if (this.retryPassword) { 
				this.submitted = false
				this.errorMsg = ''
				this.password = ''
			} else this.$router.push('/forgot-password')
		}
	}
}
</script>

<style lang="scss">
h4.error-msg{
	white-space: pre-line;
}
/*
flashdavehay!@#ERdfL 
/*/
</style>